import React from "react"
import Footer from "../components/Footer/Footer"
import TeammateHeader from "../components/Headers/TeammateHeader/TeammateHeader"
import Navbar from "../components/Navbar/Navbar"

import Background from "../images/teammateBackground.png"
import BackgroundMobile from "../images/teammateBackgroundMobile.png"
import TeammatePhoto from "../images/CameronPhoto.png"
import Teammate from "../components/Teammate/Teammate"

const teammatePage = () => {
    return (
        <div>
            <Navbar />
            <TeammateHeader
                Name="Cameron Andersen"
                Description="Project Manager"
                background={Background}
                backgroundMobile={BackgroundMobile}
                TeammatePhoto={TeammatePhoto}
            />
            <article className="w-[100%] h-auto flex items-center justify-center bg-white">
                <Teammate
                    paragraph1="cameron joined intermeta in January 2022, and has been a driving force in broadening the scope of intermeta’s capabilities ever since. With over 2 years of NFT industry and community experience, and 10 years in the television industry, he authored effective marketing campaigns, notably the “Lore of Intermeta”. Cameron assisted in the orchestration and creation of intermeta’s Genesis VAULT Card collection, the first community-based loyalty product sold to intermeta’s inaugural group of tech enthusiasts.  "

                    paragraph2="as Project Leader, Cameron has been a catalyst for bridging collaborative partnerships with various Web3 companies and aided in the planning and shipping of intermeta’s exclusive partner communications platform: interops. With intermeta setting its sights firmly on achieving widespread intermetability, he also plays a key managerial role in the development of in-house and collaboration-based intermetable asset presales. Having a keen eye for art and a strong desire for quality, he is known to regularly inject himself into the creative process of building these assets, working directly with leading artists to push the boundaries of coupling aesthetics with utility."


                />
            </article>
            <Footer />
        </div>
    )
}

export default teammatePage
